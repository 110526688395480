import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This panel lists information related the outbound campaigns actions.`}</p>
    <p>{`The following tables list the data fields of Outbound Campaigns Actions listing panel, sorted by alphabetic order. Use `}<em parentName="p">{`CRTL + F`}</em>{` in Windows or `}<em parentName="p">{`Command + F`}</em>{` in MacOS to search inside the page.`}</p>
    <h2>{`Campaign Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date when the campaign action happened in format YYYY-MM-DD. For example, `}<inlineCode parentName="td">{`2025-03-19`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Day when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Hour when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Quarter when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of action. One of the following:`}<ul><li>{`Email views.`}</li><li>{`Email link click.`}</li><li>{`Browser views.`}</li><li>{`Unsubscribed.`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Week day  when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Action year and month (YYYYMM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Year and month when the campaign action happened.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Link`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Visited link.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Device Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email client`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email client that the visitor used.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Location Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`City`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`City from where the visitor used the site.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Outbound Campaign Contact Information`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Unique identifier of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact list`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact list that the campaign used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email of the contact. For example, `}<inlineCode parentName="td">{`regular.joe@mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Email domain`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Domain of the contact email. For example, `}<inlineCode parentName="td">{`mail.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contact ID in other campaigns.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Name`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Organization`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the company that provides the Internet service to the visitor. For example, `}<inlineCode parentName="td">{`Vodafone`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Phone number of the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Outbound Campaigns`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Bcc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email blind copy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Campaign creation date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of creation of the campaign.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Cc`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email copy.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Channel`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the channel associated with the contact. One of the following: `}<ul><li>{`Voice`}</li><li>{`Video`}</li><li>{`Chat`}</li><li>{`Email`}</li><li>{`SMS`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`External campaign`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`If it exists, name of external campaign from which the customer requested the contact.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`From`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email that appears in the sent email. For example, `}<inlineCode parentName="td">{`do-no-reply@amazing-campaign.com`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Offsite campaign type`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Type of the offsite campaign responsible for the action:`}<ul><li>{`Email Outbound`}</li><li>{`SMS Outbound`}</li><li>{`Voice Outbound`}</li></ul></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Reply to`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email address to where the receiver can reply.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Skill`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Name of the skill of the operator or IVR that handled the contact.`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Send Info`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Last contact date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Date of the most recent recorded contact of the visitor.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent date (YYYYMMDD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending date.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent day (DD)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending day.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent hour (HH)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending hour.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent month (MM)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending month.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent quarter`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending quarter.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent week`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending week.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent weekday`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending weekday.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Sent year (YYYY)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Email sending year.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      